import { getField, updateField } from "vuex-map-fields";

const baseState = {
  navigation: "settings",
  settingsNavigation: "quiz",
  designNavigation: "answers",
  crud: "add",
  quizzes: [],
  currentQuiz: {
    weights: [],
    results: [],
    questions: [],
  },
  currentQuestion: {},
  currentResult: {},
  currentContent: "home",
};

const state = { ...baseState };

const getters = {
  getField,
};

const actions = {
  navigate({ commit }, data) {
    commit("set", ["navigation", data]);
  },

  navigateSettings({ commit }, data) {
    commit("set", ["settingsNavigation", data]);
  },

  navigateDesign({ commit }, data) {
    commit("set", ["designNavigation", data]);
  },

  clearCurrentQuiz({ commit }) {
    commit("set", ["currentQuiz", {}]);
    commit("set", ["currentQuestion", {}]);
    commit("set", ["currentResult", {}]);
    commit("set", ["currentContent", "home"]);
    commit("set", ["navigation", "settings"]);
    commit("set", ["settingsNavigation", "quiz"]);
    commit("set", ["designNavigation", "answers"]);
  },

  initializeCurrentQuiz({ commit }, quiz) {
    commit("set", ["currentQuiz", quiz]);
    commit("set", ["currentQuestion", quiz.questions[0]]);
    commit("set", ["currentResult", quiz.results[0]]);
    commit("set", ["currentContent", "home"]);
    commit("set", ["navigation", "settings"]);
    commit("set", ["settingsNavigation", "quiz"]);
    commit("set", ["designNavigation", "answers"]);
  },

  setCurrentQuiz({ commit }, quiz) {
    commit("set", ["currentQuiz", quiz]);
  },

  updateCurrentQuiz({ commit }, [variable, quiz]) {
    commit("setQuiz", [variable, quiz]);
  },

  setCurrentQuestion({ commit }, question) {
    commit("set", ["currentQuestion", question]);
  },

  setCurrentResult({ commit }, result) {
    commit("set", ["currentResult", result]);
  },

  setCurrentContent({ commit }, content) {
    commit("set", ["currentContent", content]);
  },

  setQuizzes({ commit }, quizzes) {
    commit("set", ["quizzes", quizzes]);
  },

  setCrud({ commit }, crudType) {
    commit("set", ["crud", crudType]);
  },
};

const mutations = {
  updateField,

  set(state, [variable, value]) {
    state[variable] = value;
  },

  setQuiz(state, [variable, value]) {
    state.currentQuiz[variable] = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
