import "@babel/polyfill";

import "core-js/stable";
import Vue from "vue";
import App from "./App";
import router from "./router";
import CoreuiVue from "@coreui/vue";
import { iconsSet as icons } from "./assets/icons/icons.js";
import store from "./store";
import Toasted from "vue-toasted";
import UUID from "vue-uuid";

import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";

const toastOptions = {
  position: "bottom-right",
  duration: 3000,
  keepOnHover: true,
  containerClass: "iemq-toast-container",
  className: "iemq-toast",
  iconPack: "material",
  action: {
    icon: "close",
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    },
  },
};

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

import "@fontsource/montserrat";
import "@fontsource/pt-serif";

Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.prototype.$log = console.log.bind(console);
Vue.use(Toasted, toastOptions);
Vue.use(UUID);

new Vue({
  el: "#app",
  router,
  store,
  icons,
  template: "<App/>",
  components: {
    App,
  },
});
