import router from "@/router";
import Cookies from "js-cookie";

export default function auth({ next, store }) {
  const authObject = Cookies.get("iemq-auth")
    ? JSON.parse(Cookies.get("iemq-auth"))
    : { user: {}, jwt: "", admin: false };

  if (authObject.jwt === "") {
    router.push({ name: "Login" });
  } else {
    if (!store.getters["auth/isAuthenticated"]) {
      store.dispatch("auth/login", authObject);
    }
  }

  return next();
}
