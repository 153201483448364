import Cookies from "js-cookie";

const baseState = {
  user: {},
  jwt: "",
  admin: false,
};

const state = { ...baseState };

const getters = {
  isAuthenticated: (state) => {
    return state.jwt !== "";
  },
};

const actions = {
  login({ commit }, data) {
    commit("set", data);
    Cookies.set("iemq-auth", data);
  },

  logout({ commit }) {
    commit("set", baseState);
    Cookies.set("iemq-auth", baseState);
  },
};

const mutations = {
  set(state, data) {
    state.user = data.user;
    state.jwt = data.jwt;
    state.admin = data.admin;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
