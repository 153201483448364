import router from "@/router";
import Cookies from "js-cookie";

export default function guest({ next }) {
  const authObject = Cookies.get("iemq-auth")
    ? JSON.parse(Cookies.get("iemq-auth"))
    : { user: {}, jwt: "", admin: false };

  if (authObject.jwt !== "") {
    router.go(-1);
  }

  return next();
}
