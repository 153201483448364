<template>
  <div>
    <router-view></router-view>
    <Loader></Loader>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";

import Loader from "./components/Loader";

const apiUrl = process.env.VUE_APP_API_URL || "http://localhost:1337";

export default {
  name: "App",

  async mounted() {
    try {
      await this.fetchUsers();
      await this.fetchQuizzes();
    } catch (error) {
      console.log({ error });
    }
  },

  components: {
    Loader,
  },

  computed: {
    ...mapState({
      jwtToken: (state) => state.auth.jwt,
    }),
  },

  methods: {
    ...mapActions({
      setUsers: "user/setUsers",
      setQuizzes: "quiz/setQuizzes",
    }),

    async fetchUsers() {
      const usersResponse = await axios.get(`${apiUrl}/users/?_limit=-1`, {
        headers: {
          Authorization: `Bearer ${this.jwtToken}`,
        },
      });

      await this.setUsers(usersResponse.data);
    },

    async fetchQuizzes() {
      const quizzesResponse = await axios.get(`${apiUrl}/quizzes/?_limit=-1`, {
        headers: {
          Authorization: `Bearer ${this.jwtToken}`,
        },
      });

      await this.setQuizzes(quizzesResponse.data);
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/style";
</style>
