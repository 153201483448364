import Vue from "vue";
import Router from "vue-router";

import middlewarePipeline from "./middlewarePipeline";
import store from "@/store";

import auth from "@/middlewares/auth";
import guest from "@/middlewares/guest";

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Views - Pages
const Login = () => import("@/views/pages/Login");

// Users
const Users = () => import("@/views/users/Users");
const User = () => import("@/views/users/User");

// Quizzes
const Quizzes = () => import("@/views/quizzes/Quizzes");
const Quiz = () => import("@/views/quizzes/Quiz");

Vue.use(Router);

const router = new Router({
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});

function configRoutes() {
  return [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {
        middleware: [guest],
      },
    },

    {
      path: "/",
      redirect: "/quizzes",
      name: "Home",
      component: TheContainer,
      children: [
        {
          path: "/profile",
          name: "Profile",
          component: User,
          meta: {
            middleware: [auth],
          },
        },
        {
          path: "quizzes",
          meta: {
            label: "Quizzes",
            middleware: [auth],
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "Quizzes",
              meta: {
                label: "Quizzes List",
                middleware: [auth],
              },
              component: Quizzes,
            },
            {
              path: "add",
              meta: {
                label: "Quiz Details",
                middleware: [auth],
                crud: "add",
                quiz: true,
              },
              name: "Add Quiz",
              component: Quiz,
            },
            {
              path: ":id",
              meta: {
                label: "Quiz Details",
                middleware: [auth],
                crud: "edit",
                quiz: true,
              },
              name: "Edit Quiz",
              component: Quiz,
            },
          ],
        },

        {
          path: "users",
          meta: {
            label: "Users",
            middleware: [auth],
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "Users",
              meta: {
                label: "User List",
                middleware: [auth],
              },
              component: Users,
            },
            {
              path: "add",
              meta: {
                label: "User Details",
                middleware: [auth],
                crud: "add",
              },
              name: "Add User",
              component: User,
            },
            {
              path: ":id",
              meta: {
                label: "User Details",
                middleware: [auth],
                crud: "edit",
              },
              name: "Edit User",
              component: User,
            },
          ],
        },
      ],
    },
  ];
}

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }
  const middleware = to.meta.middleware;

  const context = {
    to,
    from,
    next,
    store,
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
