const baseState = {
  loading: false,
  sidebarShow: "responsive",
  sidebarMinimize: false,
};

const state = { ...baseState };

const getters = {};

const actions = {
  startLoading({ commit }) {
    commit("set", ["loading", true]);
  },

  stopLoading({ commit }) {
    commit("set", ["loading", false]);
  },

  toggleSidebarDesktop({ commit }) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
    const value = sidebarOpened ? false : "responsive";

    commit("set", ["sidebarShow", value]);
  },

  showSidebarDesktop({ commit }) {
    const value = "responsive";

    commit("set", ["sidebarShow", value]);
  },

  hideSidebarDesktop({ commit }) {
    const value = false;

    commit("set", ["sidebarShow", value]);
  },

  toggleSidebarMobile({ commit }) {
    const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
    const value = sidebarClosed ? true : "responsive";

    commit("set", ["sidebarShow", value]);
  },

  toggleSidebarMinimize({ commit }) {
    commit("set", ["sidebarMinimize", !state.sidebarMinimize]);
  },

  setSidebarMinimize({ commit }, value) {
    commit("set", ["sidebarMinimize", value]);
  },
};

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : "responsive";
  },

  toggleSidebarMobile(state) {
    const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : "responsive";
  },

  set(state, [variable, value]) {
    state[variable] = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
