<template>
  <div class="component-loader" v-if="loading">
    <div class="lds-ring">
      <div
        v-for="index in 4"
        :key="index"
        :style="{ borderColor: color + ' transparent transparent transparent' }"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CLoader",

  data() {
    return {};
  },

  computed: {
    ...mapState({
      loading: (state) => state.app.loading,
    }),

    color() {
      return this.$route.name !== "Login" ? "#00338d" : "#fff";
    },
  },
};
</script>

<style lang="scss" scoped>
.component-loader {
  .lds-ring {
    display: inline-block;
    position: fixed;
    left: 30px;
    bottom: 30px;
    width: 80px;
    height: 80px;
    z-index: 9999;
  }

  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #00338d;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }

  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }

  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }

  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
