const baseState = {
  users: [],
};

const state = { ...baseState };

const getters = {};

const actions = {
  navigate({ commit }, data) {
    commit("set", ["navigation", data]);
  },

  setCurrentUser({ commit }, user) {
    commit("set", ["currentUser", user]);
  },

  setUsers({ commit }, users) {
    commit("set", ["users", users]);
  },
};

const mutations = {
  set(state, [variable, value]) {
    state[variable] = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
